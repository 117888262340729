import "./App.css";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { AppContainer } from "./modules/shared/AppContainer";
import { useIdleTimer } from "react-idle-timer";

import IframeContext from "./contexts/IframeContext";
import CalibrationContext from "./contexts/CalibrationContext";

import { checkIfIframe } from "./helpers/checkIfIframe";
import { LatusJourney } from "./userJourneys/latus/LatusJourney";

import { DemoJourney } from "./userJourneys/demo/DemoJourney";
import { NonIntegratedJourney } from "./userJourneys/non-integrated/NonIntegratedJourney";
import {
  PartyCodeContext,
  PartyCodeDispatchContext,
} from "./contexts/PartyCodeContext";
import { TestCodePage } from "./modules/home/ohc/TestCodePage";
import { AdminApi } from "./modules/hearing-test/services/admin-api";
import {
  EnabledTestsContext,
  EnabledTestsDispatchContext,
} from "./contexts/EnabledTestsContext";
import { ClinicDetails, Tests } from "./modules/shared/models/ClinicDetails";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { InactivityModal } from "./modules/inactivity/InactivityModal";
import { useAtomValue, useSetAtom } from "jotai";
import { resetAllAtoms, userDetailsAtom } from "./atoms/atoms";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { clear } from "console";
import { set } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const queryClient = new QueryClient();

export const App = () => {
  const isIframe = checkIfIframe(window.location.pathname);
  const navigate = useNavigate();

  const [clinicDetails, dispatchClinicDetails] = useReducer(clinicReducer, {
    slug: "",
    name: "",
  });
  const [enabledTests, dispatchEnabledTests] = useReducer(
    enabledTestsReducer,
    {}
  );
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const userDetails = useAtomValue(userDetailsAtom);

  // Prompt the user before they are logged out
  const onPrompt = () => {
    setShowInactivityModal(true);
  };

  // Handle the user being idle
  const onIdle = () => {
    setShowInactivityModal(false);
    handleInactivityLogout();
  };

  // Handle the user becoming active
  const onActive = () => {
    setShowInactivityModal(false);
  };

  // Reset the idle timer
  const handleReset = () => {
    reset();
    setShowInactivityModal(false);
  };

  const { reset } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: 30 * 60 * 1000, // 30 minutes
    promptBeforeIdle: 30 * 1000, // 30 seconds
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  useEffect(() => {
    if (sessionStorage.getItem("partyCode")) {
      if (sessionStorage.getItem("partyCode") === "demo") {
        dispatchClinicDetails({
          type: "setClinic",
          payload: { slug: "demo", name: "Demo" },
        });
      } else {
        AdminApi.getCompanyByTestId(sessionStorage.getItem("partyCode")!)
          .then((company) => {
            if (company.slug) {
              const { slug, name, ...tests } = company;
              dispatchClinicDetails({
                type: "setClinic",
                payload: { slug, name },
              });
              dispatchEnabledTests({
                type: "setTests",
                payload: tests,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  const [calibration, setCalibration] = useState({});

  const selectUserJourneyRoutes = (
    clinicDetails: ClinicDetails,
    isIframe: boolean
  ) => {
    if (
      window.location.href.includes("/occupational-health/latus") ||
      window.location.href.includes("/result/latus")
    ) {
      return <LatusJourney />;
    }

    switch (clinicDetails.slug) {
      case "":
        return <TestCodePage />;
      case "demo":
        return <DemoJourney />;
      default:
        return <NonIntegratedJourney />;
    }
  };

  const userJourneyRoutes = selectUserJourneyRoutes(clinicDetails, isIframe);

  const resetAtoms = useSetAtom(resetAllAtoms);

  const handleInactivityLogout = useCallback(() => {
    console.log("clearing session storage");
    let navigateToDeepLink = false;
    if (clinicDetails.slug === "latus") {
      navigateToDeepLink = true;
    }
    sessionStorage.clear();
    resetAtoms(true);
    dispatchClinicDetails({
      type: "setClinic",
      payload: { slug: "", name: "" },
    });
    dispatchEnabledTests({ type: "setTests", payload: {} });
    if (navigateToDeepLink) {
      const testId = userDetails.externalId;

      window.location.href =
        "https://yodhadatacollection.app.link/EUohp9obuDb?testid=" + testId;
    } else {
      navigate("/occupational-health");
    }
  }, [resetAtoms, clinicDetails.slug, navigate, userDetails.externalId]);

  return (
    <QueryClientProvider client={queryClient}>
      <IframeContext.Provider value={isIframe}>
        <CalibrationContext.Provider value={[calibration, setCalibration]}>
          <PartyCodeContext.Provider value={clinicDetails}>
            <PartyCodeDispatchContext.Provider value={dispatchClinicDetails}>
              <EnabledTestsContext.Provider value={enabledTests}>
                <EnabledTestsDispatchContext.Provider
                  value={dispatchEnabledTests}
                >
                  <React.Fragment>
                    {isIframe ? (
                      userJourneyRoutes
                    ) : (
                      <>
                        {showInactivityModal && (
                          <InactivityModal handleReset={handleReset} />
                        )}
                        <AppContainer>{userJourneyRoutes}</AppContainer>
                      </>
                    )}
                  </React.Fragment>
                </EnabledTestsDispatchContext.Provider>
              </EnabledTestsContext.Provider>
            </PartyCodeDispatchContext.Provider>
          </PartyCodeContext.Provider>
        </CalibrationContext.Provider>
      </IframeContext.Provider>
    </QueryClientProvider>
  );
};

function clinicReducer(
  _: any,
  action: { type: string; payload: ClinicDetails }
) {
  switch (action.type) {
    case "setClinic":
      return action.payload;
    default:
      return { slug: "demo", name: "Demo" };
  }
}

function enabledTestsReducer(_: any, action: { type: string; payload: Tests }) {
  switch (action.type) {
    case "setTests":
      return action.payload;
    default:
      return {};
  }
}

export default App;
