import React from "react";
import { Button } from "../shared/Button";

type Props = {
  handleReset: () => void;
};

export const InactivityModal = ({ handleReset }: Props) => {
  return (
    <div className="absolute w-screen h-screen bg-gray-500 bg-opacity-70 z-50 flex justify-center items-center">
      <div className="bg-white min-w-[590px] max-w-[1090px] w-2/3 h-fit rounded-3xl p-4 flex flex-col gap-4">
        <h2 className="font-bold text-2xl">Inactivity Warning!</h2>
        <h3 className="font-bold text-xl">
          You have been inactive for 30 minutes
        </h3>
        <p>
          You will be redirected to test id form screen in{" "}
          <strong>30 seconds</strong> if you don't interact with the page.
        </p>
        <Button onClick={handleReset} text="Click here to continue" />
      </div>
    </div>
  );
};
